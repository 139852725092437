









import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    ALogo: () => import('@/components/atoms/a-logo.vue')
  }
})
